import BaseModule from './utils/BaseModule'
import store from '@/store'
import { baseconfig } from "@/config.js";
class BaseManager extends BaseModule {
    constructor() {
        super()
    }
    //主数据统计信息
    bigScreenIndex(param) {
        return this.get('web/bigScreen/index', param)
    }
    //获取求美性别数据统计信息
    getSexCount(param) {
        return this.get('web/bigScreen/getSexCount', param)
    }
    //获取求美症状数据统计信息
    getSymptomCount(param) {
        return this.get('web/bigScreen/getSymptomCount', param)
    }
    //获取求美年龄段数据统计信息
    getAgeCount(param) {
        return this.get('web/bigScreen/getAgeCount', param)
    }

}

export default new BaseManager()

