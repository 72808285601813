var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-wrapper" }, [
    _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "real_time" }, [
        _c("span", { attrs: { id: "year" } }, [_vm._v(_vm._s(_vm.year))]),
        _vm._v("    "),
        _c("span", { attrs: { id: "week" } }, [_vm._v(_vm._s(_vm.week))]),
        _vm._v("    "),
        _c("span", { attrs: { id: "time" } }, [_vm._v(_vm._s(_vm.time))]),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "symptom" }, [
            _c("div", { staticClass: "symptom_title" }, [
              _vm._v("求美症状分类占比"),
            ]),
            _c(
              "div",
              { staticClass: "screen" },
              [
                _c("div", { staticClass: "dot" }),
                _c("div", { staticClass: "time_slot" }, [
                  _vm._v(
                    _vm._s(_vm._f("formatDateNew")(_vm.value1[0])) +
                      "~" +
                      _vm._s(_vm._f("formatDateNew")(_vm.value1[1]))
                  ),
                ]),
                _c(
                  "el-button",
                  {
                    staticClass: "hide_input",
                    staticStyle: {
                      background: "transparent",
                      border: "none",
                      position: "absolute",
                      "margin-right": "10px",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/img/image/drop_down.png"),
                      },
                    }),
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "timestamp",
                        type: "daterange",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      on: { change: _vm.changeTime1Handle },
                      model: {
                        value: _vm.value1,
                        callback: function ($$v) {
                          _vm.value1 = $$v
                        },
                        expression: "value1",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._m(1),
          ]),
          _c("div", { staticClass: "gender" }, [
            _c("div", { staticClass: "gender_title" }, [
              _vm._v("求美性别分类占比"),
            ]),
            _c(
              "div",
              { staticClass: "screen" },
              [
                _c("div", { staticClass: "dot" }),
                _c("div", { staticClass: "time_slot" }, [
                  _vm._v(
                    _vm._s(_vm._f("formatDateNew")(_vm.value3[0])) +
                      "~" +
                      _vm._s(_vm._f("formatDateNew")(_vm.value3[1]))
                  ),
                ]),
                _c(
                  "el-button",
                  {
                    staticClass: "hide_input",
                    staticStyle: {
                      background: "transparent",
                      border: "none",
                      position: "absolute",
                      "margin-right": "10px",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/img/image/drop_down.png"),
                      },
                    }),
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "timestamp",
                        type: "daterange",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      on: { change: _vm.changeTime3Handle },
                      model: {
                        value: _vm.value3,
                        callback: function ($$v) {
                          _vm.value3 = $$v
                        },
                        expression: "value3",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._m(2),
          ]),
        ]),
        _c("div", { staticClass: "midlle" }, [
          _c("div", { staticClass: "quantity" }, [
            _c("div", { staticClass: "qua_left" }, [
              _c("div", { staticClass: "qua_title" }, [
                _vm._v("用户数量运行监测"),
              ]),
              _c(
                "ul",
                { staticClass: "qua_ul fr-s-b" },
                _vm._l(_vm.middleInfo.userTotalCount, function (item, index) {
                  return _c("li", { key: index, staticClass: "qua_num" }, [
                    _vm._v(_vm._s(item)),
                  ])
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "qua_right" }, [
              _c("ul", { staticClass: "number_ul fr-b-c" }, [
                _c("li", { staticClass: "number_li" }, [
                  _c("div", { staticClass: "nums" }, [
                    _vm._v(_vm._s(_vm.middleInfo.userDay)),
                  ]),
                  _c("div", { staticClass: "nums_text" }, [_vm._v("日活数量")]),
                ]),
                _c("li", { staticClass: "number_li" }, [
                  _c("div", { staticClass: "cum_nums" }, [
                    _vm._v(_vm._s(_vm.middleInfo.seekBeautyLogTotalCount)),
                  ]),
                  _c("div", { staticClass: "nums_text" }, [
                    _vm._v("累计求美数"),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._m(3),
          _vm._m(4),
        ]),
        _c("div", { staticClass: "right" }, [
          _c("div", { staticClass: "age outer" }, [
            _c("div", { staticClass: "age_title" }, [_vm._v("求美年龄占比")]),
            _c(
              "div",
              { staticClass: "screen" },
              [
                _c("div", { staticClass: "dot" }),
                _c("div", { staticClass: "time_slot" }, [
                  _vm._v(
                    _vm._s(_vm._f("formatDateNew")(_vm.value2[0])) +
                      "~" +
                      _vm._s(_vm._f("formatDateNew")(_vm.value2[1]))
                  ),
                ]),
                _c(
                  "el-button",
                  {
                    staticClass: "hide_input",
                    staticStyle: {
                      background: "transparent",
                      border: "none",
                      position: "absolute",
                      "margin-right": "10px",
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../../assets/img/image/drop_down.png"),
                      },
                    }),
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "timestamp",
                        type: "daterange",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      on: { change: _vm.changeTime2Handle },
                      model: {
                        value: _vm.value2,
                        callback: function ($$v) {
                          _vm.value2 = $$v
                        },
                        expression: "value2",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._m(5),
            _c("div", { attrs: { id: "bar_y" } }),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("i", { staticClass: "chinese" }, [_vm._v("医可达大数据可视化")]),
      _c("i", { staticClass: "english" }, [
        _vm._v("Visualization of big data of medevac"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pie" }, [
      _c("div", { attrs: { id: "container" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pie" }, [
      _c("div", { attrs: { id: "container1" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "change_trend" }, [
      _c("div", { attrs: { id: "broken" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "active" }, [
      _c("div", { staticClass: "act_title" }, [_vm._v("近一年的月活跃用户")]),
      _c("div", { attrs: { id: "histogram" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "age_pie_box" }, [
      _c("div", { attrs: { id: "age_pie" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }