<template>
  <div class="page-wrapper">
    <div class="main">
      <div class="real_time">
        <span id="year">{{ year }}</span>&nbsp; &nbsp;
        <span id="week">{{ week }}</span>&nbsp; &nbsp;
        <span id="time">{{ time }}</span>
      </div>
      <div class="title">
        <i class="chinese">医可达大数据可视化</i>
        <i class="english">Visualization of big data of medevac</i>
      </div>
      <div class="content">
        <div class="left">
          <div class="symptom">
            <div class="symptom_title">求美症状分类占比</div>
            <div class="screen">
              <div class="dot"></div>
              <div class="time_slot">{{ value1[0] | formatDateNew }}~{{ value1[1] | formatDateNew }}</div>
              <el-button style="background:transparent;border:none;position:absolute; margin-right:10px" class="hide_input">
                <img src="../../../assets/img/image/drop_down.png">
                <el-date-picker value-format="timestamp" @change="changeTime1Handle" v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
              </el-button>
            </div>
            <div class="pie">
              <div id="container"></div>
            </div>
          </div>
          <div class="gender">
            <div class="gender_title">求美性别分类占比</div>
            <div class="screen">
              <div class="dot"></div>
              <div class="time_slot">{{ value3[0] | formatDateNew }}~{{ value3[1] | formatDateNew }}</div>
              <el-button style="background:transparent;border:none;position:absolute; margin-right:10px" class="hide_input">
                <img src="../../../assets/img/image/drop_down.png">
                <el-date-picker value-format="timestamp" @change="changeTime3Handle" v-model="value3" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
              </el-button>
            </div>
            <div class="pie">
              <div id="container1"></div>
            </div>
          </div>
        </div>
        <div class="midlle">
          <div class="quantity">
            <div class="qua_left">
              <div class="qua_title">用户数量运行监测</div>
              <ul class="qua_ul fr-s-b">
                <li class="qua_num" v-for="(item, index) in middleInfo.userTotalCount" :key="index">{{ item }}</li>
              </ul>
            </div>
            <div class="qua_right">
              <ul class="number_ul fr-b-c">
                <li class="number_li">
                  <div class="nums">{{ middleInfo.userDay }}</div>
                  <div class="nums_text">日活数量</div>
                </li>
                <li class="number_li">
                  <div class="cum_nums">{{ middleInfo.seekBeautyLogTotalCount }}</div>
                  <div class="nums_text">累计求美数</div>
                </li>
              </ul>
            </div>
          </div>
          <div class="change_trend">
            <div id="broken"></div>
          </div>
          <div class="active">
            <div class="act_title">近一年的月活跃用户</div>
            <div id="histogram"></div>
          </div>
        </div>
        <div class="right">
          <div class="age outer">
            <div class="age_title">求美年龄占比</div>
            <div class="screen">
              <div class="dot"></div>
              <div class="time_slot">{{ value2[0] | formatDateNew }}~{{ value2[1] | formatDateNew }}</div>
              <el-button style="background:transparent;border:none;position:absolute; margin-right:10px" class="hide_input">
                <img src="../../../assets/img/image/drop_down.png">
                <el-date-picker value-format="timestamp" @change="changeTime2Handle" v-model="value2" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
              </el-button>
            </div>
            <div class="age_pie_box">
              <div id="age_pie"></div>
            </div>
            <div id="bar_y"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import daPingManager from '@/request/daPingManager.js'
import * as echarts from 'echarts'
import { util } from '@/tools/util.js'
export default {
  data () {
    return {
      year: '',
      week: '',
      time: '',
      countDownTime: null,
      middleInfo: {
        userTotalCount: ['0'],
        seekBeautyLogTotalCount: 0,
        userDay: 0,
      },
      //近两年
      myChart1: null,
      option1: null,
      //近1年
      myChart2: null,
      option2: null,
      //省份
      myChart3: null,
      option3: null,
      //性别
      myChart4: null,
      option4: null,
      //年龄段
      myChart5: null,
      option5: null,
      //症状
      myChart6: null,
      option6: null,

      //时间
      value1: [],
      value2: [],
      value3: [],
    }
  },
  mounted () {
    this.drawChart1()
    this.drawChart2()
    this.drawChart3()
    this.drawChart4()
    this.drawChart5()
    this.drawChart6()
  },
  created () {
    this.countDownTime = setInterval(() => {
      this.showTime()
    }, 1000)

    this.setRemFontSize()
    var that = this
    window.addEventListener('resize', function () {
      setTimeout(function () {
        that.setRemFontSize()
      }, 200)
    })

    var stateTime = new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime()
    var endTime = new Date(new Date().toLocaleDateString()).getTime()
    this.value1 = [stateTime, endTime]
    this.value2 = [stateTime, endTime]
    this.value3 = [stateTime, endTime]

    this.getBigScreenIndex()
    this.getSexCount()
    this.getAgeCount()
    this.getSymptomCount()
  },
  beforeDestroy () {
    clearInterval(this.countDownTime)
  },
  filters: {
    formatDateNew (value) {
      return util.formatTime(value, 'yyyy.mm.dd')
    },
  },

  methods: {
    async getBigScreenIndex () {
      try {
        let data = await daPingManager.bigScreenIndex()
        if (data.code == 200) {
          this.middleInfo.userTotalCount = data.data.userTotalCount.toString().split('')
          this.middleInfo.seekBeautyLogTotalCount = data.data.seekBeautyLogTotalCount
          this.middleInfo.userDay = data.data.userDay

          var nowYearData = []
          var lastYearData = []
          var xLabel = []
          for (let i = 0; i < data.data.currentYearSeekBeautyLog.length; i++) {
            const obj = data.data.currentYearSeekBeautyLog[i]
            const obj1 = data.data.lastYearSeekBeautyLog[i]
            xLabel.push(obj.ymonth)
            nowYearData.push(obj.countNumber)
            lastYearData.push(obj1.countNumber)
          }

          this.option1.xAxis[0].data = xLabel
          this.option1.series[0].data = lastYearData
          this.option1.series[1].data = nowYearData
          this.myChart1.setOption(this.option1)

          var mauXLabel = []
          var mauData = []
          for (const obj of data.data.mauList) {
            Object.keys(obj).forEach((key) => {
              mauXLabel.push(key)
              mauData.push(obj[key])
            })
          }
          this.option2.xAxis.data = mauXLabel
          this.option2.series[0].data = mauData
          this.myChart2.setOption(this.option2)

          this.getHandleOption3Data(data)   //处理求美美疗师省份分布、各省份代理门店数量占比数据格式  2023-03-13 处理

        }
      } catch (error) {
        //
      }
    },
    // 处理求美美疗师省份分布、各省份代理门店数量占比 数据格式  2023-03-13 处理 加
    getHandleOption3Data (data) {
      if (this.$store.getters.roleName == '代理商') {
        var pieProvinceData = []    //2023-03-13 处理数据  饼状图   各省份代理门店数量占比
        for (const obj of data.data.storeProvinceList) {
          pieProvinceData.push({ value: obj.countNumber, name: obj.provinceName })
        }
        this.option3.series[0].data = pieProvinceData
        this.myChart3.setOption(this.option3)
      } else {
        var nurseProvinceXLabel = []    //2023-03-13 注释  柱状图数据  求美美疗师省份分布  
        var nurseProvinceData = []
        for (const obj of data.data.nurseProvinceList) {
          nurseProvinceXLabel.push(obj.provinceName)
          nurseProvinceData.push(obj.countNumber)
        }
        this.option3.yAxis.data = nurseProvinceXLabel
        this.option3.series[0].data = nurseProvinceData
        this.myChart3.setOption(this.option3)
      }
    },

    //获取求美性别数据统计信息
    async getSexCount () {
      try {
        let data = await daPingManager.getSexCount({
          startTime: this.value3[0],
          endTime: this.value3[1] * 1 + 25 * 60 * 60 * 1000 - 1,
        })
        if (data.code == 200) {
          //
          var dataArray = []
          if (data.data.length == 0) {
            dataArray.push({ value: 0, name: '男生' })
            dataArray.push({ value: 0, name: '女生' })
          } else {
            for (const obj of data.data) {
              if (obj.sex == 1) {
                dataArray.push({ value: obj.countNumber, name: '男生' })
              } else {
                dataArray.push({ value: obj.countNumber, name: '女生' })
              }
            }
          }
          this.option4.series[0].data = dataArray
          this.myChart4.setOption(this.option4)
        }
      } catch (error) {
        //
      }
    },
    //年龄段
    async getAgeCount () {
      try {
        let data = await daPingManager.getAgeCount({
          startTime: this.value2[0],
          endTime: this.value2[1] * 1 + 25 * 60 * 60 * 1000 - 1,
        })
        if (data.code == 200) {
          //
          var dataArray = []
          for (const obj of data.data) {
            console.log('年龄段=====', obj)
            Object.keys(obj).forEach((key) => {
              dataArray.push({ value: obj[key], name: key })
            })
          }
          this.option5.series[0].data = dataArray
          this.myChart5.setOption(this.option5)
        }
      } catch (error) {
        //
      }
    },
    //症状
    async getSymptomCount () {
      try {
        console.log('*********', this.value1[1] * 1 + 25 * 60 * 60 * 1000 - 1, this.value1[1] * 1);
        let data = await daPingManager.getSymptomCount({
          startTime: this.value1[0],
          endTime: this.value1[1] * 1 + 25 * 60 * 60 * 1000 - 1,
        })
        if (data.code == 200) {
          //
          var dataArray = []
          for (const obj of data.data) {
            dataArray.push({ value: obj.countNumber, name: obj.symptomName })
          }
          this.option6.series[0].data = dataArray
          this.myChart6.setOption(this.option6)
        }
      } catch (error) {
        //
      }
    },

    //近两年
    drawChart1 () {
      this.myChart1 = echarts.init(document.getElementById('broken'))
      this.option1 = {
        title: {
          text: '近两年求美次数变化趋势',
          left: 'left',
          top: 10,
          textStyle: {
            color: '#5fbbeb',
            fontSize: '16',
          },
        },
        legend: {
          align: 'left',
          right: '10%',
          top: '5%',
          type: 'plain',
          textStyle: {
            color: '#5fbbeb',
            fontSize: 14,
          },
          // icon:'rect',
          itemGap: 25,
          itemWidth: 18,
          icon: 'path://M0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
          data: [
            {
              name: '去年',
            },
            {
              name: '今年',
            },
          ],
        },

        tooltip: {
          trigger: 'axis',
          backgroundColor: 'transparent',
          axisPointer: {
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(126,199,255,0)', // 0% 处的颜色
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(126,199,255,1)', // 100% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(126,199,255,0)', // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
          formatter: (p) => {
            let dom = `<div style="width: 79px;
          height: 50px;;color:#fff;position: relative;">
                <svg style="position: absolute;top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);" class="svg" xmlns="http://www.w3.org/2000/svg" width="100" height="71" viewBox="0 0 84 55">
              <defs>
                <style>
                  .cls-1 {
                    fill: #07172c;
                    fill-opacity: 0.8;
                    stroke: #a7d8ff;
                    stroke-linejoin: round;
                    stroke-opacity: 0.2;
                    stroke-width: 1px;
                    fill-rule: evenodd;
                  }

                </style>
              </defs>
              <path id="矩形_419" data-name="矩形 419" class="cls-1" d="M266,595h74v50H266V624.046L261,620l5-3.984V595Z"
                transform="translate(-258.5 -592.5)" />
            </svg>
                <div style="padding: 4px 8px 4px 14px;display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;position: relative;z-index: 1;">
                    <div style="margin-bottom: 4px;width:100%;display:${p[0] ? 'flex' : 'none'
              };justify-content:space-between;align-items:center;">
                        <span style="font-size:14px;color:#7ec7ff;">${p[0] ? p[0].seriesName : ''
              }</span>
                        <span style="font-size:14px;color:#fff;">${p[0] ? p[0].data : ''
              }</span>
                    </div>
                    <div style="width:100%;height:100%;display:${p[1] ? 'flex' : 'none'
              };justify-content:space-between;align-items:center;">
                        <span style="font-size:14px;color:#7ec7ff;">${p[1] ? p[1].seriesName : ''
              }</span>
                        <span style="font-size:14px;color:#fff;">${p[1] ? p[1].data : ''
              }</span>
                    </div>
                </div>
            </div>`
            return dom
          },
        },

        visualMap: {
          show: false,
          min: 80,
          max: 600,
          inRange: {
            colorLightness: [0, 1],
          },
        },
        grid: {
          top: '26%',
          left: '9%',
          right: '4%',
          bottom: '15%',
          // containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              //坐标轴轴线相关设置。数学上的x轴
              show: true,
              lineStyle: {
                color: '#233653',
              },
            },
            axisLabel: {
              color: '#7ec7ff',
              interval: 0, //坐标刻度之间的显示间隔，默认就可以了（默认是不重叠）
              rotate: 38, //调整数值改变倾斜的幅度（范围-90到90
              fontSize: 11,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#274f6b',
              },
            },

            axisTick: {
              show: false,
            },
            data: [],
          },
        ],
        yAxis: [
          {
            // name: "人数",
            nameTextStyle: {
              color: '#7ec7ff',
              fontSize: 16,
              padding: 10,
            },
            min: 0,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#274f6b',
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#233653',
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#7ec7ff',
                padding: 10,
              },
              formatter: function (value) {
                if (value === 0) {
                  return value
                }
                return value
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: '去年',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 2,
                color: 'rgba(245,115,12,1)', // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: 'rgba(245,115,12,1)',
              borderColor: '#f5730c',
              borderWidth: 0,
            },
            tooltip: {
              show: true,
            },
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(245,115,12,.3)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(245,115,12, 0)',
                    },
                  ],
                  false
                ),
                shadowColor: 'rgba(245,115,12, 0.5)', //阴影颜色
                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
            data: [],
          },
          {
            name: '今年',
            type: 'line',
            symbol: 'circle', // 默认是空心圆（中间是白色的），改成实心圆
            showAllSymbol: true,
            symbolSize: 0,
            smooth: true,
            lineStyle: {
              normal: {
                width: 2,
                color: 'rgba(7,180,236,1)', // 线条颜色
              },
              borderColor: 'rgba(0,0,0,.4)',
            },
            itemStyle: {
              color: 'rgba(7,180,236,1)',
              borderColor: '#07b4ec',
              borderWidth: 0,
            },
            tooltip: {
              show: true,
            },
            areaStyle: {
              //区域填充样式
              normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(7,180,236,.3)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(7,180,236, 0)',
                    },
                  ],
                  false
                ),
                shadowColor: 'rgba(7,180,236, 0.5)', //阴影颜色
                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
            },
            data: [],
          },
        ],
      }
      this.myChart1.setOption(this.option1)
      window.addEventListener('resize', this.myChart1.resize)
    },
    //近一年
    drawChart2 () {
      this.myChart2 = echarts.init(document.getElementById('histogram'))
      this.option2 = {
        title: {
          text: '',
          subtext: '',
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['Rainfall'],
        },
        calculable: true,
        xAxis: {
          data: [],
          axisLabel: {
            color: '#7ec7ff',
            interval: 0, //坐标刻度之间的显示间隔，默认就可以了（默认是不重叠）
            rotate: 38, //调整数值改变倾斜的幅度（范围-90到90
            fontSize: 11,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#274f6b',
            },
          },
          z: 10,
        },
        yAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              color: '#274f6b',
            },
          },

          dataZoom: [
            {
              type: 'inside',
            },
          ],
        },
        series: [
          {
            type: 'bar',
            showBackground: true,
            barWidth: '10', //---柱形宽度
            barCategoryGap: '32%', //---柱形间距
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#57feff' },
                { offset: 0.5, color: '#35aae9' },
                { offset: 1, color: '#35aae9' },
              ]),
            },
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#35aae9' },
                  { offset: 0.7, color: '#57feff' },
                  { offset: 1, color: '#57feff' },
                ]),
              },
            },
            data: [],
          },
        ],
      }
      this.myChart2.setOption(this.option2)
      window.addEventListener('resize', this.myChart2.resize)
    },
    //省份
    drawChart3 () {
      if (this.$store.getters.roleName == '代理商') {
        this.drawChart3Pie()   //省份饼状图   各省份代理门店数量占比
      } else {
        this.drawChart3Col()   //省份柱状图  求美美疗师省份分布
      }
    },
    // 省份柱状图   求美美疗师省份分布
    drawChart3Col () {
      this.myChart3 = echarts.init(document.getElementById('bar_y'))
      this.option3 = {
        title: {
          text: '美疗师省份分布柱状图',
          x: 20,
          textStyle: {
            color: '#5fbbeb',
            fontSize: '16',
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          splitLine: {
            show: false,
            lineStyle: {
              color: '',
            },
          },
          axisLabel: {
            color: '#00ccff',
            fontSize: '12',
            interval: 0, //坐标刻度之间的显示间隔，默认就可以了（默认是不重叠）
            //                  rotate:38   //调整数值改变倾斜的幅度（范围-90到90
          },
        },
        yAxis: {
          type: 'category',
          data: [],
          splitLine: {
            show: true,
            lineStyle: {
              color: '#075983',
            },
          },
          axisLabel: {
            color: '#00ccff',
            fontSize: '12',
            interval: 0, //坐标刻度之间的显示间隔，默认就可以了（默认是不重叠）
            //                  rotate:38   //调整数值改变倾斜的幅度（范围-90到90
          },
        },
        series: [
          {
            name: '2011',
            type: 'bar',
            data: [],
            barWidth: '12', //---柱形宽度
            barCategoryGap: '6%', //---柱形间距
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#57feff' },
                { offset: 0.5, color: '#35aae9' },
                { offset: 1, color: '#35aae9' },
              ]),
            },
            emphasis: {
              focus: 'series',
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.3)',
            },
          },
        ],
      }
      this.myChart3.setOption(this.option3)
      window.addEventListener('resize', this.myChart3.resize)
    },
    // 省份饼状图   各省份代理门店数量占比
    drawChart3Pie () {
      this.myChart3 = echarts.init(document.getElementById('bar_y'))
      this.option3 = {
        title: {
          text: '各省份代理门店数量占比',
          x: 20,
          textStyle: {
            color: '#5fbbeb',
            fontSize: '16',
          },
        },
        series: [
          {
            name: 'Nightingale Chart',
            type: 'pie',
            radius: [30, 100],
            center: ['50%', '50%'],
            roseType: 'area',
            data: [],
            label: {
              color: '#5babe4',
            },
            itemStyle: {
              borderRadius: 5,
              normal: {
                label: {
                  show: true,
                  formatter: '{b}\n{d}%',
                  color: '#5babe4',
                  fontSize: '10',
                },
                labelLine: {
                  show: true,//数据标签引导线
                  length: 0,
                  lineStyle: {
                    width: 1,
                    type: 'solid'
                  }
                }
              },
            },
          },
        ],
      }
      this.myChart3.setOption(this.option3)
      window.addEventListener('resize', this.myChart3.resize)
    },

    //年龄
    drawChart5 () {
      this.myChart5 = echarts.init(document.getElementById('age_pie'))
      this.option5 = {
        series: [
          {
            name: 'Nightingale Chart',
            type: 'pie',
            radius: [30, 100],
            center: ['50%', '50%'],
            roseType: 'area',
            data: [
              // { value: 40, name: '18-26岁' },
              // { value: 38, name: '27-32岁' },
              // { value: 32, name: '33-46岁' },
              // { value: 30, name: '47-56岁' },
              // { value: 28, name: '57-60岁' },
            ],
            label: {
              color: '#5babe4',
            },
            itemStyle: {
              borderRadius: 5,
              normal: {
                label: {
                  show: true,
                  formatter: '{b}\n{d}%',
                  color: '#5babe4',
                  fontSize: '10',
                },
                labelLine: {
                  show: true,//数据标签引导线
                  length: 0,
                  lineStyle: {
                    width: 1,
                    type: 'solid'
                  }
                }
              },
            },
          },
        ],
      }
      this.myChart5.setOption(this.option5)
      window.addEventListener('resize', this.myChart5.resize)
    },
    //性别
    drawChart4 () {
      this.myChart4 = echarts.init(document.getElementById('container1'))
      this.option4 = {
        series: [
          {
            name: 'Nightingale Chart',
            type: 'pie',
            radius: [50, 74],
            center: ['50%', '50%'],
            roseType: 'area',

            data: [
              // { value: 40, name: '男生' },
              // { value: 40, name: '女生' },
            ],
            label: {
              color: '#5babe4',
            },
            itemStyle: {
              borderRadius: 5,
              normal: {
                label: {
                  show: true,
                  formatter: '{b}\n{d}%',
                  color: '#5babe4',
                  fontSize: '10',
                },
                labelLine: {
                  show: true,//数据标签引导线
                  length: 0,
                  lineStyle: {
                    width: 1,
                    type: 'solid'
                  }
                }
              },
            },
          },
        ],
      }
      this.myChart4.setOption(this.option4)
      window.addEventListener('resize', this.myChart4.resize)
    },
    //症状
    drawChart6 () {
      this.myChart6 = echarts.init(document.getElementById('container'))
      this.option6 = {
        title: {
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          bottom: '10%',
          itemWidth: 10,
          itemHeight: 10,
          left: 'center',
          textStyle: {
            color: '#5babe4',
          },
        },
        series: [
          {
            type: 'pie',
            radius: '60%',

            center: ['50%', '35%'],
            data: [
              // { value: 1048, name: '真皮斑' },
              // { value: 735, name: '混合斑' },
              // { value: 580, name: '雀斑' },
              // { value: 484, name: '色素痣' },
              // { value: 300, name: '晒斑' },
            ],
            label: {
              color: '#5babe4',
            },
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  formatter: '{b}\n{d}%',
                  color: '#5babe4',
                  fontSize: '10',
                },
                labelLine: {
                  show: true,//数据标签引导线
                  length: 0,
                  lineStyle: {
                    width: 1,
                    type: 'solid'
                  }
                }
              },
            },
          },
        ],
      }

      this.myChart6.setOption(this.option6)
      window.addEventListener('resize', this.myChart6.resize)
    },

    changeTime1Handle (row) {
      this.getSymptomCount();
    },
    changeTime2Handle (row) {

      this.getAgeCount();
    },

    changeTime3Handle (row) {
      this.getSexCount();
    },

    setRemFontSize () {
      var remSize = window.innerWidth / 19.2
      document.querySelector('html').style.fontSize = remSize + 'px'
    },
    showTime () {
      var today = new Date()
      var y = today.getFullYear()
      var M = today.getMonth() + 1
      var d = today.getDate()
      var w = today.getDay()
      var h = today.getHours()
      var m = today.getMinutes()
      var s = today.getSeconds()
      var week = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
      m = this.checkTime(m)
      s = this.checkTime(s)
      M = this.checkTime(M)
      d = this.checkTime(d)

      this.year = y + '/' + M + '/' + d
      this.week = week[w]
      this.time = h + ':' + m + ':' + s
    },
    checkTime (i) {
      if (i < 10) {
        i = '0' + i
      }
      return i
    },
  },
}
</script>

<style scoped>
.hide_input {
  position: absolute !important;
  top: -10px;
}

/* //修改控件自带的css */
.hide_input .el-date-editor {
  width: 200px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
}

.page-wrapper {
  font-size: 26.67vw;
  width: 100%;
  height: 100%;
  background: url(../../../assets/img/image/bg.png) no-repeat;
  background-size: 100% 100%;
  /* 下面三行知道为啥这么加吗  是为了不让屏幕上下滚动 永远固定在首屏 不要动这3行代码*/
  position: fixed;
  left: 0;
  top: 0;
}

.main {
  width: 95%;
  margin: 0 auto;
  position: relative;
}

.outer {
  zoom: 1;
}

/*==for IE6/7 Maxthon2==*/
.outer:after {
  clear: both;
  content: "";
  display: block;
  width: 0;
  height: 0;
  visibility: hidden;
}

.real_time {
  position: absolute;
  left: 0;
  top: 0.72rem;
  font-size: 0.18rem;
  line-height: 0.48rem;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
  color: #56b2e2;
  background: url(../../../assets/img/image/box.png) no-repeat;
  background-size: 100% 100%;
  width: 5.28rem;
  height: 0.48rem;
  padding-left: 0.16rem;
}

.title {
  width: 50%;
  margin: 0 auto;
  text-align: center;
  padding-top: 0.26rem;
}

.chinese {
  font-size: 0.42rem;
  font-family: "百度综艺简体";
  background-image: -webkit-linear-gradient(bottom, #d5d5d5, #fefefe);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
}

.english {
  font-family: "迷你简中倩";
  font-size: 0.24rem;
  color: #ebf2ff;
  display: block;
  margin-top: -0.1rem;
}

.content {
  width: 98%;
  margin: 0 auto;
  margin-top: 0.5rem;
  /* overflow: hidden; */
}

.left {
  float: left;
  width: 24%;
}

.symptom {
  background: url(../../../assets/img/image/symptom.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 4rem;
}

.symptom_title {
  font-size: 0.24rem;
  font-family: "百度综艺简体";
  color: #fefefe;
  float: left;
  font-weight: 600;
  line-height: 0.36rem;
  background: url(../../../assets/img/image/Title_box.png) no-repeat;
  background-size: 100% 100%;
  width: 3.3rem;
  height: 0.36rem;
  margin-left: 0.1rem;
  margin-top: -0.1rem;
  padding-left: 0.1rem;
}

.screen {
  margin-top: 0.3rem;
  float: right;
  margin-right: 0.7rem;
  position: relative;
  z-index: 99;
}

.dot {
  width: 0.1rem;
  height: 0.1rem;
  background-color: #00ccff;
  border-radius: 100%;
  margin-top: 0.02rem;
  float: left;
}

.time_slot {
  font-size: 0.12rem;
  color: #fff;
  line-height: 0.12rem;
  margin-left: 0.1rem;
  float: left;
}

.drop_down {
  width: 0.12rem;
  height: 0.12rem;
  margin-left: 0.1rem;
  float: left;
  position: relative;
}

.drop_down img {
  position: absolute;
  top: -0.1.2em;
}

.screen .drop_ul {
  position: absolute;
  display: none;
  left: -0.85rem;
  top: 0;
  padding-top: 0.3rem;
  z-index: 6;
}

.screen .drop_ul ul {
  background-color: #1c4d8e;
  border-radius: 0.04rem;
  padding: 0.1rem;
  cursor: pointer;
}

.screen .drop_down:hover .drop_ul {
  display: block;
}

.screen .drop_li {
  font-size: 0.14rem;
  color: #fff;
  line-height: 16px;
  text-align: center;
  padding: 0.16rem 0.1rem;
  cursor: pointer;
}

.screen .drop_li:hover {
  color: #41fcfa;
}

.pie {
  width: 90%;
  margin: 0 auto;
  padding-top: 1rem;
}

#container {
  height: 3rem;
}

.gender {
  background: url(../../../assets/img/image/Gender.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 3rem;
  margin-top: 0.3rem;
}

.gender_title {
  font-size: 0.24rem;
  font-family: "百度综艺简体";
  color: #fefefe;
  float: left;
  font-weight: 600;
  line-height: 0.36rem;
  background: url(../../../assets/img/image/Title_box.png) no-repeat;
  background-size: 100% 100%;
  width: 3.3rem;
  height: 0.36rem;
  margin-left: 0.1rem;
  margin-top: -0.1rem;
  padding-left: 0.1rem;
}

#container1 {
  height: 2rem;
}

.midlle {
  float: left;
  width: 45%;
  margin-left: 0.66rem;
}

.quantity {
  overflow: hidden;
}

.qua_left {
  float: left;
}

.qua_title {
  font-size: 0.28rem;
  font-family: "百度综艺简体";
  color: #fff;
}

.qua_ul {
  overflow: hidden;
  margin-top: 0.3rem;
}

.qua_num {
  width: 0.42rem;
  height: 0.68rem;
  line-height: 0.68rem;
  color: #95ffff;
  font-size: 0.56rem;
  font-family: "经典粗黑简";
  background-color: #1c4d8e;
  text-align: center;
  margin-right: 0.18rem;
}

.qua_right {
  float: right;
}

.number_ul {
  overflow: hidden;
  margin-top: 0.2rem;
}

.number_li {
  margin-left: 0.4rem;
}

.nums {
  background: url(../../../assets/img/image/dali.png) no-repeat;
  background-size: 100% 100%;
  width: 1rem;
  height: 1rem;
  font-size: 0.2rem;
  color: #02f6f8;
  line-height: 1rem;
  text-align: center;
}

.cum_nums {
  background: url(../../../assets/img/image/Cumulative.png) no-repeat;
  background-size: 100% 100%;
  width: 1rem;
  height: 1rem;
  font-size: 0.2rem;
  color: #02f6f8;
  line-height: 1rem;
  text-align: center;
}

.nums_text {
  text-align: center;
  font-size: 0.2rem;
  color: #5babe4;
  margin-top: 0.1rem;
}

.change_trend {
  width: 100%;
  margin-top: 0.36rem;
}

#broken {
  width: 100%;
  height: 2.8rem;
}

.active {
  width: 100%;
  overflow: hidden;
}

.act_title {
  font-size: 0.16rem;
  color: #5fbbeb;
  padding-top: 0.2rem;
}

#histogram {
  width: 100%;
  height: 2.6rem;
}

.right {
  width: 24%;
  float: right;
}

.age {
  background: url(../../../assets/img/image/Age.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 7.3rem;
}

.age_title {
  font-size: 0.24rem;
  font-family: "百度综艺简体";
  color: #fefefe;
  float: left;
  font-weight: 600;
  line-height: 0.36rem;
  background: url(../../../assets/img/image/Title_box.png) no-repeat;
  background-size: 100% 100%;
  width: 3.3rem;
  height: 0.36rem;
  margin-left: 0.1rem;
  margin-top: -0.1rem;
  padding-left: 0.1rem;
}

.age_pie_box {
  width: 90%;
  margin: 0 auto;
  padding-top: 0.5rem;
}

#age_pie {
  height: 3rem;
}

#bar_y {
  height: 3.6rem;
  width: 90%;
  margin: 0 auto;
}
</style>